import React, { useEffect } from 'react';
import 'styles/global.scss';
import 'styles/components/index.scss';
//import "util/analytics.js";
import PlausibleProvider from 'next-plausible';
import Head from 'next/head';
import ChatBubble from 'components/chatBubble';
import dynamic from 'next/dynamic';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Script from 'next/script';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { authProviders } from 'util/auth';
import useStore from 'components/sharedCode/stores/store';
import { firebaseApp } from 'util/firebase';

const Navbar = dynamic(() => import('components/Navbar'));
const Footer = dynamic(() => import('components/Footer'));
const auth = getAuth(firebaseApp);

function MyApp({ Component, pageProps }) {
    const description =
        'Transform your browser workflow. Use drag and drop or one-click to save your tabs into groups. Add notes, to-dos, text-snippets, sync and share all in your new tab.';
    const twitterHandle = '@tabextend';
    const currentURL = 'https://www.tabextend.com';
    const siteName = 'tabExtend Home';
    const pageTitle =
        'tabExtend - Save and organize tabs, add notes, and share';
    const previewImage = 'https://www.tabextend.com/og_preview.jpg';

    const Layout = Component.Layout ? Component.Layout : React.Fragment;
    useEffect(() => {
        // Subscribe to user on mount
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const providers = user.providerData.map(({ providerId }) => {
                    return authProviders.find((p) => p.id === providerId).name;
                });
                //@ts-ignore
                user = { ...user, providers: providers };
                useStore.setState({ authUser: user });
            } else {
                useStore.setState({ authUser: false });
            }
        });

        // Unsubscribe on cleanup
        return () => unsubscribe();
    }, []);
    if (Component.isFullPage) {
        return <Component {...pageProps} />;
    }

    return (
        <PlausibleProvider domain="tabextend.com">
            <ChakraProvider theme={theme}>
                <Head>
                    <title>{pageTitle}</title>
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicon-16x16.png"
                    />
                    <link rel="manifest" href="/site.webmanifest" />
                    <link
                        rel="mask-icon"
                        href="/safari-pinned-tab.svg"
                        color="#5bbad5"
                    />
                    <meta name="msapplication-TileColor" content="#2b5797" />
                    <meta name="theme-color" content="#f4f5f7" />
                    <meta name="description" content={description} />
                    {/* <meta
          name="twitter:card"
          content="Clear and save your tabs in an efficient way"
          key="twcard"
        />
        <meta name="twitter:creator" content={twitterHandle} key="twhandle" /> */}
                    <meta property="og:url" content={currentURL} key="ogurl" />
                    <meta
                        property="og:image"
                        content={previewImage}
                        key="ogimage"
                    />
                    <meta
                        property="og:site_name"
                        content={siteName}
                        key="ogsitename"
                    />
                    <meta
                        property="og:title"
                        content={pageTitle}
                        key="ogtitle"
                    />
                    <meta property="og:type" content="website" key="ogtype" />
                    <meta
                        property="og:description"
                        content={description}
                        key="ogdesc"
                    />
                    <meta
                        property="twitter:card"
                        content="summary_large_image"
                    />
                    <meta
                        property="twitter:url"
                        content="https://www.tabextend.com/"
                    />
                    <meta
                        property="twitter:title"
                        content="tabExtend - Save and organize tabs, add notes, and share"
                        key="tweetTitle"
                    />
                    <meta
                        property="twitter:description"
                        content="Upgrade your browser and transform your workflow. Use drag and drop to clear your tabs or one-click save them all. Add notes and to-dos, save text-snippets, sync and share all in your new tab"
                        key="tweetDesc"
                    />
                    <meta
                        property="twitter:image"
                        content="https://www.tabextend.com/og_preview.jpg"
                        key="tweetImg"
                    />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="robots" content="index, follow" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="canonical" href={currentURL} />
                    {typeof window !== 'undefined' && (
                        <>
                            <Script strategy="lazyOnload">
                                {`
        (function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments);}})(window,'rewardful');
      `}
                            </Script>
                            <Script
                                src="https://r.wdfl.co/rw.js"
                                data-rewardful="51839e"
                                strategy="lazyOnload"
                            />
                           
                        </>
                    )}
                </Head>
                {/* <ProvideAuth> */}
                <Script id="chatra" strategy="lazyOnload">
                    {`
                        (function(d, w, c) {
                            w.ChatraID = 'T6SBrPEPPbiy7fCvN';
                            var s = d.createElement('script');
                            w[c] = w[c] || function() {
                                (w[c].q = w[c].q || []).push(arguments);
                            };
                            s.async = true;
                            s.src = 'https://call.chatra.io/chatra.js';
                            if (d.head) d.head.appendChild(s);
                        })(document, window, 'Chatra');
                    `}
                </Script>
                <>
                    <Navbar
                        color="white"
                        spaced={true}
                        logo="/tabExtendWebLogo@2x.png"
                    ></Navbar>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                    <ChatBubble />
                    <Footer
                        color="light"
                        size="normal"
                        backgroundImage=""
                        backgroundImageOpacity={1}
                        copyright={`© ${new Date().getFullYear()} tabExtend`}
                        logo="/tabExtendWebLogo@2x.png"
                    ></Footer>
                    <div id="modal" />
                </>
                {/* </ProvideAuth> */}
            </ChakraProvider>
        </PlausibleProvider>
    );
}

export default MyApp;

const theme = extendTheme({
    components: {
        Button: {
            baseStyle: {
                //bg: 'linear-gradient(180deg, #F7F9FA 0%, #F1F4F8 54%, #E4EAF2 100%)',
                //border: 'var(--chakra-colors-teBorder)',
                transition: '0.1s'
            }
        },
        IconButton: {
            // Default props for the Button component
            defaultProps: {
                transition: '0.1s'
            }
        },
        Input: {
            defaultProps: {
                focusBorderColor: '#5ba6ff'
            }
        },
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#54a5fe'
                    }
                }
            }
        }
    }
});
