import React, { useState, useRef, useEffect } from 'react';
import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';
import SectionButton from 'components/SectionButton';
import { CTAButton, CTAbuttonSection } from './CTAButton';
import HeroText from './HeroText';
import Link from 'next/link';
import HeroSpace from './HeroSpace';
import useScrollPosition from '../util/useScrollPos';
import { useInView, InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import Image from 'next/image';
import OtherBrowser from './OtherBrowser';
import { CloudIcon, PlayButton } from 'components/IconBlocks';
import dynamic from 'next/dynamic';
import OnetimeEmailForm from 'components/onetimeEmailForm';
import tabextend_overview_drop from '../../public/heroMock/tabextend_overview_drop.png';

const LandingFeatures = dynamic(() => import('./LandingFeatures'));
const Threesteps = dynamic(() => import('./Threesteps'));
const VideoModal = dynamic(() => import('./VideoModal'));

const BrandsSection = () => {
    return (
        <>
            <div
                className="brandsRow1"
                style={{
                    display: 'flex',
                    marginTop: '2rem',
                    marginBottom: '2.4rem',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    maxWidth: '86%'
                }}
            >
                <Image
                    height={74}
                    width={149}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/uber.png'}
                    alt="Uber logo"
                />
                <Image
                    height={74}
                    width={171}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/google.png'}
                    alt="Google logo"
                />
                <Image
                    height={74}
                    width={84}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/twitter.png'}
                    alt="Twitter logo"
                />
                <Image
                    height={74}
                    width={144}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/loba.png'}
                    alt="Loba logo"
                />
            </div>
            <div
                className="brandsRow2"
                style={{
                    display: 'flex',
                    marginBottom: '4rem',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    maxWidth: '86%'
                }}
            >
                <Image
                    height={74}
                    width={161}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/github.png'}
                    alt="Github logo"
                />

                <Image
                    height={74}
                    width={184}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/airtable.png'}
                    alt="Airtable logo"
                />
                <Image
                    height={74}
                    width={223}
                    unoptimized={true}
                    quality={100}
                    src={'/brands/freshwork.png'}
                    alt="Freshwork logo"
                />
            </div>
        </>
    );
};

const WriteItem = ({ icon, title, text, inView, delay, ...props }) => {
    return (
        <>
            <div
                /* animate={{ scale: inView ? 1 : 0 }}
        transition={{
          duration: 0.35,
          delay: 0.4 + delay,
          type: "spring",
          stiffness: 100,
          bounce: 0.5,
        }} */
                style={{
                    height: '54px',
                    width: '54px',
                    background: '#F4F5F7',
                    backgroundImage:
                        'linear-gradient(180deg, #F4F5F7 0%, #ECEDF2 100%)',
                    boxShadow:
                        '0 1px 3px 0 rgba(103,109,132,0.30), inset 1px 1px 1px 0 rgba(255,255,255,0.95), inset -1px -1px 1px 0 rgb(192 194 197)',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {props.children}
            </div>
            <h3
                className="has-text-weight-bold"
                style={{ fontSize: '1.325rem', marginTop: '1.8rem' }}
            >
                {title}
            </h3>
            <p
                className="subtitle"
                style={{
                    fontFamily: 'Rubik, sans-serif',
                    color: '#4A545E',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    paddingBottom: '2rem',
                    marginTop: '0.8rem'
                }}
            >
                {text}
            </p>
        </>
    );
};

const BlueItem = ({ inView }) => {
    return (
        <>
            <motion.div
                animate={{
                    scale: inView ? 1 : 0
                }}
                transition={{
                    duration: 0.4,
                    delay: 0.3,
                    type: 'spring',
                    stiffness: 100,
                    bounce: 0.5
                }}
                style={{
                    height: '54px',
                    width: '54px',
                    background: '#F4F5F7',
                    backgroundImage:
                        'linear-gradient(134deg, #228AFF 0%, #0062FF 100%)',
                    boxShadow:
                        'inset 1px 1px 1px 0 rgba(232,236,243,0.20), inset -1px -2px 2px 0 rgba(8,23,46,0.20)',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <motion.div
                    animate={{ scale: inView ? 1 : 0 }}
                    transition={{
                        duration: 0.4,
                        delay: 0.32
                    }}
                    style={{ margin: 'auto', display: 'flex' }}
                >
                    <CloudIcon
                        IconColor={'#FFF'}
                        height={'40px'}
                        width={'36px'}
                    />
                </motion.div>
            </motion.div>
        </>
    );
};

function HeroSection(props) {
    const [hoverLink, setHoverLink] = useState(false);
    const [hoverHero, setHoverHero] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);

    return (
        <>
            <Section
                className={'heroBg'}
                color={props.color}
                size={props.size}
                backgroundImage={props.backgroundImage}
                backgroundImageOpacity={props.backgroundImageOpacity}
                style={{
                    position: 'relative'
                    //backgroundImage: "linear-gradient(225deg, #97efca 0%, #AECFE1 100%)",
                }}
            >
                <div className="container" style={{ textAlign: 'center' }}>
                    <header
                        className={
                            'SectionHeader' +
                            (props.className ? ` ${props.className}` : '')
                        }
                        style={{
                            marginBottom: '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {/* <a
              href="https://www.producthunt.com/posts/tabextend-2-0?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-tabextend&#0045;2&#0045;0"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=415283&theme=neutral&period=daily"
                alt="tabExtend&#0032;2&#0046;0 - More&#0032;efficient&#0032;browser&#0032;workflows | Product Hunt"
                style={{ width: "250px", height: "54px", transform: "scale(0.9)" }}
                width="250"
                height="54"
              />
            </a> */}
                        <h1
                            className={
                                'title is-1 is-size-2-mobile heroLine is-spaced'
                            }
                            style={{
                                zIndex: '10',
                                color: '#04184a' /* marginTop: "1rem" */
                            }}
                        >
                            Better than bookmarks
                        </h1>
                        <h2 className={'subHeroLine'} style={{ zIndex: '10' }}>
                            {/* Reduce multitasking and stay focused by dropping tabs not needed at the moment. Add notes and reminders, all inside your browser. */}
                            Stay focused by getting an overview of your tabs.
                            Add notes, images, and reminders, all inside your browser.
                        </h2>
                    </header>
                    {/* <div
            className="heroBgBox1"
            style={{
              //y: box1y,
              //rotate: -10,
              transform: "rotate(-10deg)",
              position: "absolute",
              background: "rgba(223,255,251,0.50)",
              border: "2px solid rgba(255,255,255,0.50)",
              boxShadow: "inset -2px -2px 25px 0 rgba(255,255,255,0.80)",
              borderRadius: "20px",
              backdropFilter: "blur(6px)",
              zIndex: "6",
            }}
          />
          <div
            className="heroBgBox2"
            style={{
              //y: box2y,
              //rotate: -10,
              position: "absolute",
              transform: "rotate(-10deg)",
              backgroundImage:
                " linear-gradient(136deg, #FF9482 0%, #FF8C93 10%, #FE84A4 21%, #FD7BB4 31%, #FB72C5 42%, #F869D5 52%, #E46DDD 62%, #CF70E6 71%, #B873EE 81%, #9D75F7 90%, #7D77FF 100%)",
              borderRadius: "20px",
              boxShadow: "20px 142px 104px 0 rgba(0,0,0,0.20)",
              zIndex: "5",
            }}
          />
          <div
            className="heroBgBox3"
            style={{
              //y: box3y,
              //rotate: -10,
              position: "absolute",
              transform: "rotate(-10deg)",
              background: "rgba(223,255,251,0.50)",
              border: "2px solid rgba(255,255,255,0.50)",
              boxShadow: "inset -2px -2px 25px 0 rgba(255,255,255,0.80)",
              borderRadius: "20px",
              backdropFilter: "blur(6px)",
              zIndex: "4",
              opacity: "0.9",
            }}
          />
          <div
            className="heroBgBox4"
            style={{
              //y: box4y,
              //rotate: -10,
              position: "absolute",
              transform: "rotate(-10deg)",
              backgroundImage: " linear-gradient(134deg, #016AEA 0%, #BA0086 100%)",
              borderRadius: "20px",
              //boxShadow: "20px 142px 104px 0 rgba(0,0,0,0.20)",
              zIndex: "3",
            }}
          /> */}
                </div>
            </Section>
            <Section
                color={props.color}
                size={props.size}
                backgroundImage={props.backgroundImage}
                backgroundImageOpacity={props.backgroundImageOpacity}
                style={{ paddingTop: '0rem', zIndex: '12' }}
            >
                <div className="container pb-0">
                    <div className="column" style={{ perspective: '600px' }}>
                        <motion.div
                            onHoverStart={(e) => {
                                setHoverHero(true);
                            }}
                            onHoverEnd={(e) => {
                                setHoverHero(false);
                            }}
                            className="heroVideo"
                            onClick={() => setVideoOpen(true)}
                            whileHover={{
                                boxShadow:
                                    '0 8px 32px 0 rgba(0, 0, 0, 0.25), 0 45px 100px -12px rgba(101, 111, 119, 0.40)'
                                //filter: "drop-shadow(rgba(0, 0, 0, 0.44) 0px 8px 24px)",
                            }}
                            whileTap={{ scale: 0.98 }}
                            //ref={heroRef}
                            style={{
                                //z: cardPosZ,
                                //filter: "blur(6px)",

                                display: 'inline-flex',
                                cursor: 'pointer',
                                zIndex: '0',
                                opacity: '1',
                                marginBottom: '3.2rem',
                                borderRadius:
                                    '1.5957446808511% / 3.0456852791878%',
                                overflow: 'hidden',
                                position: 'relative',
                                //filter: "drop-shadow(rgba(0, 0, 0, 0.36) 0px 8px 32px)",
                                boxShadow:
                                    '0 2px 14px 0 rgba(0, 0, 0, 0.15), 0 30px 60px -12px rgba(101, 111, 119, 0.35)'
                            }}
                        >
                            {/* <div
                style={{
                  overflow: "hidden",
                  borderRadius: "1.5957446808511% / 3.0456852791878%",
                  display: "block",
                  //position: "relative",
                }}
              > */}
                            <Image
                                priority={true}
                                height={880}
                                width={1678}
                                quality={100}
                                placeholder="blur"
                                //style={{display: "block", opacity: cardOpacity, filter: picFilter}}
                                src={tabextend_overview_drop}
                                alt="Extention Screenshot"
                                objectFit="cover"
                            />
                            {/* </div> */}
                            <motion.div
                                animate={{ scale: hoverHero ? 1.1 : 1 }}
                                transition={{ type: 'spring', bounce: 0.5 }}
                                style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    top: '50%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    left: '0',
                                    y: '-60px',
                                    right: '0',
                                    width: '120px',
                                    height: '120px',
                                    borderRadius: '50%',
                                    opacity: '0.55',
                                    backgroundImage:
                                        'linear-gradient(135deg, #67707A 0%, #A2A8B0 100%)',
                                    boxShadow:
                                        '0 12px 54px -5px rgba(0,0,0,0.60)',
                                    willChange: 'scale',
                                    overflow: 'hidden'
                                }}
                            >
                                <PlayButton />
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
                {videoOpen && <VideoModal setVideoOpen={setVideoOpen} />}
                <div
                    className="has-text-centered brands"
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <p
                        style={{
                            color: '#8A95A0',
                            fontSize: '16px',
                            fontWeight: '500'
                        }}
                    >
                        Used by 20 000+ productive people at companies like
                    </p>
                    <BrandsSection />
                    <div className="buttons is-inline-flex">
                        {/* <CTAButton parentColor={props.color} size="medium" onClick={props.buttonOnClick}>
              {props.buttonText}
            </CTAButton> */}
                        <CTAbuttonSection />
                    </div>
                    <div
                        className="CTAsubtitle"
                        style={{
                            fontWeight: '700',
                            letterSpacing: '0.48px',
                            color: '#4A545E',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            marginTop: '8px'
                        }}
                    >
                        TRY FOR FREE
                        <span style={{ marginRight: '4px' }} /> • NO SIGN-UP
                        NEEDED
                        <span style={{ marginRight: '4px' }} /> • NO DOWNLOAD
                    </div>
                    <div className="Stars"></div>
                    <OtherBrowser />
                </div>
                <div
                    className="has-text-centered brands is-hidden-desktop"
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div
                        style={{
                            marginTop: '2rem',
                            backgroundColor: '#0078FF',
                            padding: '1rem 3rem 3rem 3rem',
                            marginLeft: '-3rem',
                            marginRight: '-3rem'
                        }}
                    >
                        <p className="isFFF" style={{ margin: '1rem' }}>
                            <b>Hey! It looks like you're on a mobile/tablet.</b>{' '}
                            tabExtend is a desktop browser extension. Send
                            yourself a one-time email with a reminder to try out
                            tabExtend(your email won't be stored).
                        </p>
                        <div
                            style={{
                                maxWidth: '600px',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <OnetimeEmailForm />
                        </div>
                    </div>
                </div>
                {/* <header
          className={
            "SectionHeader" + (props.className ? ` ${props.className}` : "")
          }
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "6rem",
            marginBottom: "0rem",
          }}
        >
          <h2 className={"title is-1 is-size-2-mobile is-spaced hero2Line"}>
            Multitasking is according to science not only bad for your cognitive
            abilities, concentration, and stress-levels, it can even lower your
            IQ.
          </h2>
          <p
            className={"subtitle is-6"}
            style={{
              textAlign: "center",
              fontFamily: "Rubik, sans-serif",
              color: "#4A545E",
              fontSize: "1rem",
              lineHeight: "1.46",
              letterSpacing: "0.48px",
              maxWidth: "452px",
            }}
          >
            <Link
              href="https://www.forbes.com/sites/curtsteinhorst/2020/02/20/how-multitasking-erodes-productivity-and-dings-your-iq/"
              alt="Forbes article"
              className="SectionHeader__no-classname"
            >
              How Multitasking Erodes Productivity And Dings Your IQ,
              Steinhorst, Forbes, 2020-02-20
            </Link>
          </p>
        </header> */}
                {/*         <div className="container pb-0" style={{ maxWidth: "762px" }}>
          <div className="column">
            <div
              //ref={heroRef}
              style={{
                //z: cardPosZ,
                //filter: "blur(6px)",
                zIndex: "0",
                opacity: "1",
                marginBottom: "3.2rem",
                //width: "640px",
                margin: "auto",
                borderRadius: "1.5957446808511% / 3.0456852791878%",
                boxShadow:
                  "0 2px 8px 0 rgba(205,205,205,0.80), 0 15px 30px -12px rgba(155,168,180,0.50)",
                position: "relative",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  borderRadius: "1.5957446808511% / 3.0456852791878%",
                  display: "block",
                  position: "relative",
                }}
              >
                <Image
                  loading={"lazy"}
                  height={436}
                  width={740}
                  quality={90}
                  //style={{display: "block", opacity: cardOpacity, filter: picFilter}}
                  src={"/features/first_drop.gif"}
                  alt="Extention Screenshot"
                />
              </div>
            </div>
          </div>
        </div> */}
            </Section>
            <LandingFeatures />
            <Threesteps />
        </>
    );
}

export default HeroSection;
