import React from "react";

function NavbarContainer(props) {
  const { color, spaced, className, dashboard, ...otherProps } = props;

  const navStyle = dashboard ? {
    position: "sticky",
    top: "0"
  } : null;

  return (
    <nav
      className={
        "navbar" +
        (color ? ` is-${color}` : "") +
        (spaced ? " is-spaced" : "") +
        (className ? ` ${className}` : "")
      }
      {...otherProps}
      style={navStyle}
    >
      {props.children}
    </nav>
  );
}

export default NavbarContainer;
