import React from 'react';
import HeroSection from 'components/HeroSection';
import ClientsSection from 'components/ClientsSection';
import FeaturesSection from 'components/FeaturesSection';
import FeaturesSection2 from 'components/FeaturesSection2';
import CtaSection from 'components/CtaSection';
import NewsletterSection from 'components/NewsletterSection';
import { useRouter } from 'next/router';
import FullWidthArea from 'components/FullWidthArea';
import { usePlausible } from 'next-plausible';
import Testamonials from 'components/testamonials';
import dynamic from 'next/dynamic';
import Head from 'next/head';

const CTAbottomSection = dynamic(() => import('components/CTAbottomSection'));

function IndexPage(props) {
    const router = useRouter();

    // Remove query parameters for canonical URL
    const canonicalUrl = `https://www.tabextend.com${router.pathname}`;

    return (
        <>
            <Head>
                <title>tabExtend - Browser Tab Manager</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:url" content={canonicalUrl} />
            </Head>
            <HeroSection
                color="white"
                size="medium"
                backgroundImage=""
                backgroundImageOpacity={1}
                title="You need focus to reach your goals"
                imgTitle="Refine your browser workflow"
                imgSubtitle="Stop saving websites into note apps or letting them disappear as bookmarks that you will never find again. tabExtend replaces your default new tab screen and gives your browser superpowers right where it's needed."
                subtitle="Clear up your web browser with our extension. Manage tabs with ease, add notes and to-dos, always just one click away."
                buttonText="Try for free"
                image="/heroSpace.png"
                buttonOnClick={() => {
                    // Navigate to pricing page
                    //router.push("/pricing");
                    //router.push("/#earlybird");
                    //plausible('Click-CTA-Top');
                }}
            ></HeroSection>
            {/* <ClientsSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        title=""
        subtitle="COMING TO ALL MAJOR BROWSERS"
      ></ClientsSection> */}
            {/* <FeaturesSection
        color="white"
        size="small"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Save time and focus"
        subtitle="Do you have alot of tabs open that you dont want to lose? Drag and drop them into groups, add notes and to-dos to create a plan on what to do next and feel at ease."
      ></FeaturesSection> */}
            {/* <FeaturesSection2
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
      ></FeaturesSection2>
      <FullWidthArea size="medium" color="#15171B" /> */}
            {/*       <CtaSection
        color="dark"
        size="medium"
        title="Reduce tab excess and get a new found focus!"
        buttonText="Explore"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      ></CtaSection> */}
            {/* <NewsletterSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Recive updates about tabExtend"
        subtitle="No spam ever, only hear from us when we release new features"
        buttonText="Notify me!"
        inputPlaceholder="Your email"
        subscribedMessage="You are now subscribed!"
      ></NewsletterSection> */}
            <CTAbottomSection
                color="white"
                size="medium"
                backgroundImage=""
                backgroundImageOpacity={1}
            ></CTAbottomSection>
        </>
    );
}

export default IndexPage;
